import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    components:{
      listStatuses: ()=> import('../listStatuses/index.vue')
    },
    data() {
        return {
            form: {
                title: '',
                description: '',
                np_title: 'Нова Пошта',
                np_logo: 'http://textilelux.lp-crm.biz/mods/nova_poshta/logo.png',
                np_module: 'nova_poshta',
                np_description: 'Курьерская служба №1 в Украине',
                np_status: 1,
                np_phone: '',
                np_firstname: '',
                np_lastname: '',
                np_middlename: '',
                np_api_key: '',
                np_area:null,
                np_city:null,
                np_warehouse:null,
                np_counterparty_sender:null,
                np_counterparty_address:null,
                np_list_matched_statuses: [{
                    crm_status:'',
                    order_status:''
                }],
            },
            list: {
            },
            npAreasList:[],
            npAreasListId:null,
            npCityList:[],
            npCityListId:null,
            npCityWarehouses:[],
            npCityWarehousesId:null,
            contragent: [
                {
                    id:'none',
                    title:'Нету'
                }
            ],
            address:[
                {
                    id:'none',
                    title:'Нету'
                }
            ]
        }
    },

    validations: {
        form: {
            np_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
            npWarehouses: 'plugins/npWarehouses',
            warehouse: 'warehouse/warehouse',
            npAreas: 'plugins/npAreas',
            npCity: 'plugins/npCity',
            counterpartySender: 'plugins/counterpartySender',
            counterpartyAddress: 'plugins/counterpartyAddress',
        }),
    },
    watch:{
        npAreas(e){
            this.npAreasList = [];
            this.npCityList = [];
            this.list.np_area = null;
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npAreasList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
            this.checkOption(this.options);
        },
        npCity(e){
            this.npCityList = [];
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        npWarehouses(e){
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityWarehouses.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        options(e){
            this.checkOption(e);
        },
        counterpartySender(e){
            this.contragent = [];
            let key;
            for(key in e.models){
                this.contragent.push({
                    id: key,
                    title: e.models[key],
                })
            }
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        counterpartyAddress(e){
            this. address = [];
            let key;
            for(key in e.models){
                this.address.push({
                    id: key,
                    title: e.models[key],
                })
            }
        }
    },
    created() {
        this.getNpAreas();
        this.checkOption(this.option);
        this.getCounterpartySender();
    },
    methods: {
        checkOption(res){
            if(res){
                this.form = res.models;
                if(!this.form.np_list_matched_statuses){
                    this.form.np_list_matched_statuses = [];
                }
                this.selectedArea();
                this.selectedCity()
            }else{
                this.form = this.list
            }
        },
        addToStatusList(){
            this.form.np_list_matched_statuses.push({
                srm_status: '',
                order_status: ''
            })
        },
        removeStatusItem(item){
            const index = this.form.np_list_matched_statuses.indexOf(item);
            this.form.np_list_matched_statuses.splice(index, 1);
        },
        selectedArea(){
            this.getNpCity({'area_id': this.form.np_area})
        },
        selectedCity(){
            this.getNpWarehouses({'city_id': this.form.np_city})
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
        getSenderAddress(){
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        ...mapActions({
            getNpAreas:'plugins/getNpAreas',
            getNpCity:'plugins/getNpCity',
            getNpWarehouses:'plugins/getNpWarehouses',
            getCounterpartySender:'plugins/getCounterpartySender',
            getCounterpartyAddress:'plugins/getCounterpartyAddress',
        }),
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
